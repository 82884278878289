import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
//import Auth0logo from "../images/Auth0-logo.png"
//import { Login} from "../pages/account"

const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `orange`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `purple`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <h4>
    
      <ul style={{ listStyle: `none`, float: `right` }}>
          <ListLink to="/blog">Posts</ListLink>
          <ListLink to="/projects/">Sites</ListLink>
          <ListLink to="/account/">⚙︎</ListLink>
        </ul>
      </h4>
    </div>
    <div class="s9-widget-wrapper"></div>
    
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
